import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import './styles/app.scss';
import App from './App';
import { ParticlesComponent } from './components/ParticlesComponent';
import { ID_ANALYTICS } from './env';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(ID_ANALYTICS);

root.render(
  <React.StrictMode>
    <ParticlesComponent/>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
