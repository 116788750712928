import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import Logo from '../src/imgs/logo.png'
import { Card } from './components/Card';
import Youtube from './imgs/youtube.png'

import { CID } from './env';
import { data } from './data/data';


function App() {
  const [activeCards, setActiveCards] = useState([]);
  const [cardsData, setCardsData] = useState(data);

  const getLastVideo = async()=> {
    const cid = CID
    const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${cid}`)
    const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`;

    fetch(reqURL)
        .then(response => response.json())
        .then(result => {
          const lastVideo = result.items[0].link
          const youtube =  { id: 1, 
            icon:Youtube ,
            title: 'Youtube', 
            content: [
              {type:'text',content:'¿Necesitas ayuda con tus clases?', styles:{fontWeight:'bold'}},
              {type:'text',content:'En nuestro canal encontrarás videos super didácticos de química y biología.'},
              {type:'text',content:'¡Checa nuestro último video y suscríbete!'},
              {type:'video',content:lastVideo},
              {type:'btn',buttonText:'Suscríbete', content:'https://www.youtube.com/@Alfinentendi' }
            ] 
          }
          let prevCardsData = cardsData;
          prevCardsData[0] = youtube
          setCardsData(prevCardsData)
        })
        .catch(error => console.log('error', error));
  }
  useEffect(() => {
    getLastVideo()
  }, [])
  

  const handleCardClick = (id, title)=> {
    if (activeCards.includes(id)) {
      setActiveCards(activeCards.filter((cardId) => cardId !== id));
    } else {
      setActiveCards([...activeCards, id]);

      ReactGA.event({
        category: "Card category",
        action:title,
        label: "tarjeta seleccionada", 
      });
    }
  }
  return (
    <div className='app'>
      <header className={`${activeCards.length > 0 ? 'active' : ''}`}>
        <img src={Logo} alt="Al Fin Entendi Logo" />
        <p>¡Hola! Bienvenido a la Comunidad:</p> 
        <h2>Al Fin Entendī</h2> 
        <p style={{textAlign:'center'}}>Te invitamos a descubrir nuestra increíble variedad de recursos didácticos que tenemos especialmente para ti.</p>
      </header>
      <main>
        {
          cardsData.map((card) => (
            <Card 
            icon={card.icon}
            key={card.id}
            id={card.id}
            title={card.title}
            content={card.content}
            isActive={activeCards.includes(card.id)}
            onCardClick={(id, title)=>handleCardClick(id, title)}
            />
          ))
        }
       

      </main>
      <footer>
        <div className="links">
          <div className="icon"><a target='_blank' rel="noreferrer" href="https://www.facebook.com/alfinentendi"><i className="fa-brands fa-facebook"></i></a></div>
          {/* <div className="icon"><a target='_blank' rel="noreferrer" href=""><i className="fa-brands fa-twitter"></i></a></div> */}
          <div className="icon"><a target='_blank' rel="noreferrer" href="mailto:alfinentendi@gmail.com"><i className="fa-solid fa-envelope"></i></a></div>
          <div className="icon"><a target='_blank' rel="noreferrer" href="https://www.instagram.com/al_fin_entendi/"><i className="fa-brands fa-instagram"></i></a></div>
        </div>

      </footer>
    </div>
  );
}

export default App;
