import React, { useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);



export const Card = ({ id,icon, title, content, isActive, onCardClick }) => {
    const main = useRef();
    

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
          const boxes = self.selector('.box');
          boxes.forEach((box, index) => {
            const direction = index % 2 === 0 ? -100 : 100;
            const start = index % 2 === 0 ? "top bottom" : "top bottom";
            if (id%2 === 0) {
                gsap.from(box, {
                  x:direction,
                  opacity: 0,
                  duration: 1,
                  scrollTrigger: {
                    trigger: box,
                    start: start,
                    end: "center center",
                    scrub: false,
                  },
                });
            }else{
                gsap.from( box, {
                    y: 100,
                    ease: "power4.out",
                    skewY: 5,
                    scrollTrigger: {
                        trigger: box,
                        scrub: false,
                      },
                  })
            }
          });
        }, main); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }, [isActive, id]);


  return (
    <div   className={`card-container ${isActive ? 'active' : ''}`} >
        <div onClick={(e) => { title === 'Youtube' && window.scrollTo( 0, 40 ); onCardClick(id, title)}} className="card-button">
            
            <div className="card-icon" style={typeof icon !=='string' ? {display:'flex', gap:'.5rem'}:{} }>
                {
                    typeof icon === 'string'?
                    <img src={icon} alt={title} />
                    :
                    icon.map((i) => <img src={i} alt={title} />)
                }
            </div>
            <div className="card-title">{title}</div>

        </div>
        {
            isActive &&
            <div className="card-body" ref={main}>
                {
                    content.map((element, i)=> (
                        <>
                        
                            {
                                element.type === 'text'? 
                                <div className="card-text box">
                                    <p style={element.styles || {}} dangerouslySetInnerHTML={{ __html: element.content }}></p>
                                </div>
                                :
                                element.type === 'img'?
                                <div className="card-img box">
                                    <img style={{width:"100%"}} src={element.content} alt="" />
                                </div>
                                :
                                element.type === 'btn'?
                                <div style={element.style} className="card-button box">
                                    <a target='_blank' rel="noreferrer" className='btn btn-primary' href={element.content}>{element.buttonText || 'Clic Aquí'}</a>
                                </div>
                                :
                                element.type === 'video'?
                                <div className="card-video box">
                                    <iframe width="100%" height="auto" src={element.content.replace('watch?v=','embed/')} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                                :
                                null
                            }
                       </>
                    ))
                }
                
               
            </div>

        }
    </div>
  )
}
