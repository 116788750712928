import Youtube from '../imgs/youtube.png'
import Libro from '../imgs/libro.png'
import Libro2 from '../imgs/quimica_en_accion.jpg'
import Kofi from '../imgs/kofi.png'
import Kahoot from '../imgs/kahoot.png'
import Instagram from '../imgs/Instagram.png'
export const data = [
    { id: 1, 
      icon:Youtube ,
      title: 'Youtube', 
      content: [
        {type:'text',content:'¿Necesitas ayuda con tus clases?', styles:{fontWeight:'bold'}},
        {type:'text',content:'En nuestro canal encontrarás videos super didácticos de química y biología.'},
        {type:'text',content:'¡Checa nuestro último video y suscríbete!'},
        {type:'video',content:"https://www.youtube.com/watch?v=PPpUUbfMTko&t=623s"},
        {type:'btn',buttonText:'Suscríbete', content:'https://www.youtube.com/@Alfinentendi' }
      ] 
    },
    { id: 2, 
      icon:Instagram ,
      title: 'Instagram', 
      content: [
        {type:'text',content:'Síguenos para conocer a toda la comunidad y estar al tanto de todas las novedades que tenemos preparadas para ti'},
        {type:'btn',buttonText:'Seguir', content:'https://www.instagram.com/al_fin_entendi/' }
      ] 
    },
    { id: 3, 
      icon:[Libro,Libro2] ,
      title: 'Libros y Guías', 
      content: [
        {type:'text',content:'<b>Al fin entendí: Química 1</b> es un libro novedoso que te explica de forma clara, concreta y muy ilustrativa conceptos fundamentales de Química'},
        {type:'text',content:'Conoce todo el contenido y más detalles en este video:'},
        {type:'video',content:'https://www.youtube.com/watch?v=vP2kn6fWgwg'},
        {type:'btn',buttonText:'Libro', content:'https://www.amazon.com.mx/Al-fin-entend%C3%AD-Qu%C3%ADmica-Spanish/dp/B0BHNFHDBV/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=', style:{marginBottom:"1rem"} },
        {type:'text',content:'<b>Química en acción</b> es la guía definitiva para dominar el mundo de la química y superar tus exámenes, especialmente un examen de admisión a la universidad.'},
        {type:'text',content:'Conoce todo el contenido y más detalles en este video:'},
        {type:'video',content:'https://www.youtube.com/watch?v=dnxTY0A3BD0'},
        {type:'btn',buttonText:'Guía', content:'https://www.amazon.com.mx/Qu%C3%ADmica-acci%C3%B3n-pr%C3%A1ctica-admisi%C3%B3n-universidad-ebook/dp/B0CXMNCS1S/ref=sr_1_2?dib=eyJ2IjoiMSJ9.02D8u9VlENzWATQqOQqXJvdqypAOwKvxXLaYLSQqd4pVFjN7r2iR-8-v8gPHrqZ3.ohfr4wyJwtAM7WuPEhRyfEHObeorlq5qsB1O72o64EE&dib_tag=se&qid=1713675352&refinements=p_27%3ADaniel+Alejandro+Rodr%C3%ADguez+Gallardo&s=books&sr=1-2&text=Daniel+Alejandro+Rodr%C3%ADguez+Gallardo' },
      ] 
    },
    { id: 4, 
      icon:Kahoot ,
      title: 'Practica con nuestros KAHOOTS', 
      content: [
        {type:'text',content:'Aprende y diviértete con nuestra gran variedad de juegos Kahoot. ¡Son excelentes para que superes tu próximo examen!'},
        {type:'btn',content:'https://create.kahoot.it/profiles/c752c694-3f9c-4df1-b044-dd9a2e2bb62e', buttonText: 'Visitar sitio'}
      ] 
    },
    { id: 5, 
      icon:Kofi ,
      title: 'Tabla periodica con formulario', 
      content: [
        {type:'text',content:'Descarga totalmente gratis nuestra increíble tabla periódica. ¡Incluye las principales fórmulas que necesitarás para aprobar Química!'},
        {type:'btn',content:'https://ko-fi.com/s/e717390ed2', buttonText: 'Descargar'}
      ] 
    },
    // Agrega más datos de cards aquí
  ]